import React from 'react';
import { Route, RouteProps } from "react-router-dom";

type GuardFunc = () => boolean;

interface IProps extends RouteProps {
    guard: GuardFunc | GuardFunc[];
};

const SafeRoute = ({ guard, ...rest }: IProps) => {
    if (Array.isArray(guard) && guard.some(item => !item())) return null;
    if (!(guard as GuardFunc)()) return null;

    return <Route {...rest} />;
};

export default SafeRoute;