import { passwordValidator } from "platform/services/validator";

export const initialForm = {
  email: '',
  code: '',
  newPassword: '',
};

export const formValidations = {
  newPassword: passwordValidator,
};