
/* injects from baggage-loader */
require('./style.scss');
import { Route } from 'react-router';
import Copyright from 'components/copyright';
import ROUTES from 'platform/constants/routes';
import React from 'react';

import { CompleteAccountContextProvider } from "./contexts/complete-account";
import UserTypeStep from './routes/user-type-step';
import BusinessTypeStep from './routes/business-type-step';
import CategoryStep from './routes/category-step';
import SupplierStep from './routes/supplier-step';

const CompleteAccount = () => (
    <section className="P-complete-account-page">
        <CompleteAccountContextProvider>
            <div className="P-step-content">
                <Route exact path={ROUTES.COMPLETE_ACCOUNT.MAIN} component={UserTypeStep} />
                <Route exact path={ROUTES.COMPLETE_ACCOUNT.BUSINESS_TYPE} component={BusinessTypeStep} />
                <Route exact path={ROUTES.COMPLETE_ACCOUNT.CATEGORY} component={CategoryStep} />
                <Route exact path={ROUTES.COMPLETE_ACCOUNT.SUPPLIER} component={SupplierStep} />
            </div>
            <Copyright />
        </CompleteAccountContextProvider>
    </section>
);

export default CompleteAccount;