
/* injects from baggage-loader */
import React, { ReactNode, useEffect, useRef } from "react";

import SuccessModal from "components/success-modal";
import AlertModals from "platform/services/alertModals";
import ConfirmModal from "components/confirm-modal";

interface IProps {
    children: ReactNode,
};

const AlertModalsWrapper = ({ children }: IProps) => {
    const successModalRef = useRef<Function>();
    const confirmModalRef = useRef<Function>();

    useEffect(() => {
        successModalRef.current && AlertModals.add('success', successModalRef.current);
        confirmModalRef.current && AlertModals.add('confirm', confirmModalRef.current);
    }, [successModalRef, confirmModalRef]);

    return <>
        <SuccessModal ref={successModalRef} />
        <ConfirmModal ref={confirmModalRef} />
        {children}
    </>;
};

export default AlertModalsWrapper;