
/* injects from baggage-loader */
import React, { memo, useEffect, useContext } from 'react';
import useTranslations from 'platform/hooks/use-translations';
import useForm from 'platform/hooks/use-form';
import { IRestorePasswordRequestModel } from 'platform/api/auth/models/request';
import { formValidations, initialForm } from './form';
import AuthController from 'platform/api/auth';
import { Link, useHistory } from 'react-router-dom';
import ROUTES from 'platform/constants/routes';
import FormField from 'components/form-field';
import { AuthContext } from 'contexts/auth';
import useQueryParams from 'platform/hooks/use-query-params';

interface IQueryParams {
    email?: string;
    code?: string;
};

const RestorePassword = () => {
    const history = useHistory();
    const [, setCredientals] = useContext(AuthContext);
    const [queryParams] = useQueryParams<IQueryParams>({});
    const { form, setForm, buildField, submitted, onSubmit, formValid } = useForm<IRestorePasswordRequestModel>(initialForm, formValidations);
    const texts = useTranslations('auth');

    useEffect(() => {
        const { email } = queryParams;

        if (!email) history.push(ROUTES.AUTH.FORGOT_PASSWORD);
        else if (queryParams.code) setForm({ ...form, email, code: +queryParams.code || '0' });
        else setForm({ ...form, email });
    }, [queryParams]);


    useEffect(() => {
        (async () => {
            if (submitted && formValid) {
                const result = await AuthController.RestorePassword(form);
                if (result.data) {
                    setCredientals(result.data);
                    history.push(result.data.isAccountComplete ? '' : ROUTES.COMPLETE_ACCOUNT.MAIN);
                }
            }
        })();
    }, [submitted]);

    const resend = async () => {
        const result = await AuthController.RestoreResend({ username: form.email });

        if (result.success) {
            const alertify = await import('alertifyjs');
            alertify.success(texts.resend_code_description);
        }
    }

    return <>
        <h2 className="P-title">{texts.restore_password_title}</h2>

        <form className="G-form" onSubmit={onSubmit}>
            {!queryParams.code && <FormField onlyInt title={texts.restore_password_field_code} {...buildField('code', { type: 'password' })} />}
            
            <FormField title={texts.restore_password_field_new_password} {...buildField('newPassword', { type: 'password' })} />

            <button className="G-button">{texts.restore_password_submit}</button>
        </form>

        <p className="P-bottom-text">You didn’t receive verification code? <a onClick={resend}>Resend</a></p>
    </>
};

export default memo(RestorePassword);