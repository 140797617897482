import Connection from '../../services/connection';
import { IResponse } from '../../constants/interfaces';
import { ICategoryListResponseModel } from './models/response';
import { ICategoryListRequestModel } from './models/request';

const controller = 'category';

class CategoryController {

  public static List = (query?: ICategoryListRequestModel): Promise<IResponse<ICategoryListResponseModel[]>> => {
    const result = Connection.GET({
      query,
      action: '',
      controller,
    });

    return result;
  };

  public static SupplierList = (query?: ICategoryListRequestModel): Promise<IResponse<ICategoryListResponseModel[]>> => {
    const result = Connection.GET({
      query,
      action: 'supplierList',
      controller,
    });

    return result;
  };
};

export default CategoryController;