import { DependencyList, MutableRefObject, useEffect, useRef } from "react";

const useAsyncPrompt = <InputData extends any[], ResolveValue = unknown, RejectValue = unknown>(
    forwardedRef: MutableRefObject<Function | undefined>,
    promptCallback: (...args: InputData) => void,
    dependencies: DependencyList = [],
) => {
    type ResolveFunc = (value: ResolveValue) => void;
    type RejectFunc = (value: RejectValue) => void;

    const promptHandlers = useRef<{
        resolve?: ResolveFunc,
        reject?: RejectFunc,
    }>({});

    useEffect(() => {
        forwardedRef.current = (...args: InputData) => {
            const promptPromise = new Promise<ResolveValue | RejectValue>((resolve, reject) => {
                promptHandlers.current = {
                    resolve: resolve as ResolveFunc,
                    reject: reject as RejectFunc,
                };
            });

            promptCallback(...args);

            return promptPromise;
        };
    }, [forwardedRef, ...dependencies]);

    return promptHandlers.current;
};

export default useAsyncPrompt;