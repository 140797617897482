
/* injects from baggage-loader */
require('./style.scss');
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import ROUTES from 'platform/constants/routes';
import LeftBlock from './components/left-block';

import SignIn from './routes/sign-in';
import SignUp from './routes/sign-up';
import Verification from './routes/verification';
import ForgotPassword from './routes/forgot-password';
import RestorePassword from './routes/restore-password';
import Copyright from '../../components/copyright';
import Screen from 'components/screen';

const Auth = () => {
  return <section className="P-auth-page">
    <Screen.Min_Tablet>
      <LeftBlock />
    </Screen.Min_Tablet>

    <section className="P-auth-page-content">
      <Route exact path={ROUTES.AUTH.MAIN} component={SignIn} />
      <Route exact path={ROUTES.AUTH.SIGN_UP} component={SignUp} />
      <Route exact path={ROUTES.AUTH.VERIFICATION} component={Verification} />
      <Route exact path={ROUTES.AUTH.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact path={ROUTES.AUTH.RESTORE_PASSWORD} component={RestorePassword} />
      {/* <Redirect to={ROUTES.AUTH.MAIN} /> */}

      <Copyright />
    </section>
  </section>
}

export default Auth;
