
/* injects from baggage-loader */
require('./style.scss');
import Steps from 'modules/complete-account/components/steps';
import { CompleteAccountStepsEnum } from 'modules/complete-account/constants/enums';
import { CompleteAccountContext } from 'modules/complete-account/contexts/complete-account';
import { UserTypeEnum } from 'platform/api/user/constants/enums';
import useTranslations from 'platform/hooks/use-translations';
import React from 'react';
import { useContext } from 'react';

const UserTypeStep = () => {
    const [stepsData, changeStepData] = useContext(CompleteAccountContext);
    const completeAccountTexts = useTranslations('complete_account');

    const userType = stepsData[CompleteAccountStepsEnum.UserType]?.data;

    const setUserType = (type: UserTypeEnum) => changeStepData(CompleteAccountStepsEnum.UserType, {
        data: type
    });

    return <>
        <Steps currentStepType={CompleteAccountStepsEnum.UserType} />

        <div className="P-complete-account-user-type">
            <div
                className={userType === UserTypeEnum.Customer ? 'P-active' : ''}
                onClick={() => setUserType(UserTypeEnum.Customer)}
            >{completeAccountTexts.user_type_customer}</div>

            <div
                className={userType === UserTypeEnum.Supplier ? 'P-active' : ''}
                onClick={() => setUserType(UserTypeEnum.Supplier)}
            >{completeAccountTexts.user_type_supplier}</div>
        </div>
    </>;
};

export default UserTypeStep;