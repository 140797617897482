import { Dispatch, HTMLProps, SetStateAction, SyntheticEvent, useEffect, useState } from "react";
import useFormValidator, { FormErrors, FormValidationPattern } from "./use-form-validator";
import _ from "lodash";

export interface IForm<TForm extends object | object[]> {
  form: TForm;
  setForm: Dispatch<SetStateAction<TForm>>;
  submitted: boolean;
  setSubmitted(value: boolean): void;
  submitDisabled: boolean;
  setSubmitDisabled(value: boolean): void;
  onSubmit(e: SyntheticEvent): void;
  buildField: BuildFieldFunc<TForm>;
  formErrors: FormErrors<TForm>;
  formValid: boolean;
};

type ValueCalculatorFunc = (e: SyntheticEvent) => any;

const fieldDefaultValueCalculator = (e: SyntheticEvent) => (e.currentTarget as HTMLInputElement).value || undefined;

type BuildFieldFunc<TForm extends object | object[]> = TForm extends object[]
  ? (key: string, currentFormIndex: number, inputProps?: HTMLProps<HTMLInputElement>, valueCalculator?: ValueCalculatorFunc) => any
  : (key: string, inputProps?: HTMLProps<HTMLInputElement>, valueCalculator?: ValueCalculatorFunc) => any;

interface IFormConfig {
  readOnly?: boolean;
}

const useForm = <TForm extends object | object[]>(initialForm: TForm, validationPattern?: FormValidationPattern<TForm>, formConfig?: IFormConfig) => {
  const { readOnly } = formConfig || {};
  const [form, setForm] = useState(initialForm);
  const [submitted, setSubmitted] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const { errors, valid } = useFormValidator<TForm>(form, validationPattern);

  const onSubmit = (e?: SyntheticEvent) => {
    e?.preventDefault();
    !submitDisabled && setSubmitted(true);
  }

  useEffect(() => { submitted && valid && setSubmitted(false); }, [submitted, valid]);

  const inputVal = (value?: string | number) => (
    value || value === 0
      ? value.toString()
      : ''
  );

  const buildField = (
    key: string,
    inputProps?: HTMLProps<HTMLInputElement>,
    valueCalculator = fieldDefaultValueCalculator,
  ) => ({
    errored: submitted && _.get(errors, key),
    inputProps: {
      name: key,
      value: inputVal(_.get(form, key)),
      readOnly,
      onChange: (e: SyntheticEvent) => {
        if (inputProps?.readOnly) return;

        const newForm = _.cloneDeep(form);

        _.set(newForm, key, valueCalculator(e));
        setForm(newForm);
      },
      ...(inputProps || {})
    }
  });

  const buildFieldArray = (
    key: string,
    currentFormIndex: number = 0,
    inputProps?: HTMLProps<HTMLInputElement>,
    valueCalculator = fieldDefaultValueCalculator,
  ) => ({
    errored: submitted && _.get(errors[currentFormIndex], key),
    inputProps: {
      name: key,
      value: inputVal(_.get(form[currentFormIndex], key)),
      readOnly,
      onChange: (e: SyntheticEvent) => {
        if (inputProps?.readOnly) return;

        const newForm = [...form as object[]];
        const newFormItem = _.cloneDeep(newForm[currentFormIndex]);

        _.set(newFormItem, key, valueCalculator(e) || undefined);
        newForm[currentFormIndex] = newFormItem;

        setForm(newForm as TForm);
      },
      ...(inputProps || {})
    }
  });

  return {
    form, setForm, submitted, setSubmitted, submitDisabled, setSubmitDisabled, onSubmit,
    buildField: (Array.isArray(form) ? buildFieldArray : buildField) as BuildFieldFunc<TForm>,
    formErrors: errors, formValid: valid,
  };
}

export default useForm;