export const modalHandlers = {};

interface IModalHandler {
    [key: string]: Function;
};

//TODO think about Alert modals flow (maybe need to be simplified on component level Prompts part)

class AlertModals {
    private static modalHandlers: IModalHandler = {};

    static add(type: string, trigger: Function) {
        AlertModals.modalHandlers[type] = trigger;
    }

    static open(type: string, data?: any) { //TODO think about data type
        return AlertModals.modalHandlers[type]?.(data);
    } 
}

export default AlertModals;