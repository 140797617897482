
/* injects from baggage-loader */
require('./style.scss');
import React, { useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

import ClickOutside from '../click-outside';
import useTranslations from 'platform/hooks/use-translations';

interface IProps {
  title?: string;
  children: React.ReactNode;
  className?: string;
  onClose?(): void;
  closable: boolean;
  usePortal?: boolean;
};

const Modal = ({
  title,
  onClose,
  closable,
  className,
  children,
  usePortal,
}: IProps) => {
  useLayoutEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => { document.body.style.overflowY = 'initial'; }
  }, []);

  const close = (e: MouseEvent | React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    closable && onClose?.();
  }

  const titleBarShown = title || closable;
  const basketTexts = useTranslations('basket');

  const content = <div className={`P-modal-wrapper ${className || ''}`}>
    <ClickOutside onClickOutside={close}>
      <div key="modal-content" className="P-modal-content">
        {titleBarShown && <div className="P-modal-title-bar">
          {title && <h3>{title}</h3>}
          {closable && <i className="P-close icon-close" onClick={close} />}
        </div>}
        
        <div>{children}</div>
      </div>
    </ClickOutside>
  </div>

  return usePortal ? createPortal(
    content,
    document.getElementById('modals') as Element,
  ) : content;
}

Modal.defaultProps = {
  usePortal: true,
  closable: true,
};

export default Modal;