
/* injects from baggage-loader */
require('./style.scss');
import React, { forwardRef, HTMLProps, RefObject, SyntheticEvent, useMemo } from 'react';


interface IProps extends HTMLProps<HTMLInputElement> {
  int?: boolean;
  value?: string | number,
  max?: number,
};

const NumberInputComponent = (
  { int, max, value, onChange, ...inputProps }: IProps,
  ref: RefObject<HTMLInputElement>,
) => {
  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const isValid = e.currentTarget.validity.valid;
    if (isValid) {
      if ((max || max === 0) && +e.currentTarget.value > max) e.currentTarget.value = max.toString();
      onChange?.(e);
    }
  }

  const pattern = useMemo(() => {
    if (int) return '([0-9]*)';
    return '[0-9]+([\.,](([0-9]?){1,2}))?';
  }, [int]);

  return <input
    ref={ref}
    value={value?.toString() || ''}
    pattern={pattern}
    onChange={handleChange}
    {...inputProps}
  />;
};

const NumberInput = forwardRef(NumberInputComponent);

export default NumberInput;
