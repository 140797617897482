
/* injects from baggage-loader */
import { IUserAddressResponseModel } from 'platform/api/user/models/response';
import React, { Fragment, ReactNode } from 'react';

// replace {1} {2} etc ... with dynamic values inside text 
export const dynamicText = (text: string, ...replacements: (string | number)[]) => {
    let filledText = text;

    replacements.forEach((item, index) => {
        filledText = filledText.replace(`{${index + 1}}`, item.toString());
    });

    return filledText;
};

// replace {1} {2} etc ... with dynamic components inside text 
export const dynamicComponentText = (text: string, ...replacements: (string | number | ReactNode)[]) => {
    const parts: ReactNode[] = text.split(/{[0-9]}/);

    replacements.forEach((item, index) => {
        parts[index + 1] = <Fragment key={index}>{item}{parts[index + 1]}</Fragment>;
    });

    return parts;
};

export const getAddressText = (address: IUserAddressResponseModel) => {
    if (!address) return '';

    return `${address.text}, ${address.city}, ${address.postalCode}, ${address.country}`;
}

export const getBase64URL = (contentType: string, bytes: string) => {
    const byteCharacters = window.atob(bytes);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++)
        byteNumbers[i] = byteCharacters.charCodeAt(i);

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });

    return URL.createObjectURL(blob);
}