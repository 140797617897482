import Connection from '../../services/connection';
import { IResponse } from '../../constants/interfaces';
import { ILoginResponseModel, ISendCodeResponseModel } from './models/response';
import { ILoginRequestModel, IRegisterRequestModel, ISendCodeRequestModel, IVerifyRequestModel, IRecoveryRequestModel, IVerificaitonRequestModel, IForgotPasswordRequestModel, IRestorePasswordRequestModel, IChangePaswordRequestModel, IRestoreResendRequestModel } from './models/request';

const controller = 'auth';

class AuthController {

  public static Login = (body: ILoginRequestModel): Promise<IResponse<ILoginResponseModel>> => {
    const result = Connection.POST({
      body,
      action: 'login',
      controller,
    });

    return result;
  };

  public static Register = (body: IRegisterRequestModel): Promise<IResponse<ILoginResponseModel>> => {
    const result = Connection.POST({
      body,
      action: 'register',
      controller,
    });

    return result;
  };

  public static ForgotPassword = (body: IForgotPasswordRequestModel): Promise<IResponse<number>> => {
    const result = Connection.POST({
      body,
      action: 'forgotPassword',
      controller,
    });

    return result;
  };

  public static RestorePassword = (body: IRestorePasswordRequestModel): Promise<IResponse<ILoginResponseModel>> => {
    const result = Connection.POST({
      body,
      action: 'restorePassword',
      controller,
    });

    return result;
  };

  public static ChangePassword = (body: IChangePaswordRequestModel): Promise<IResponse<boolean>> => {
    const result = Connection.PUT({
      body,
      action: 'changePassword',
      controller,
    });

    return result;
  };

  public static Verification = (body: IVerificaitonRequestModel): Promise<IResponse<boolean>> => {
    const result = Connection.POST({
      body,
      action: 'verification',
      controller,
    });

    return result;
  };

  public static Resend = (): Promise<IResponse<boolean>> => {
    const result = Connection.POST({
      body: {},
      action: 'resend',
      controller,
    });

    return result;
  };

  public static RestoreResend = (body: IRestoreResendRequestModel): Promise<IResponse<boolean>> => {
    const result = Connection.POST({
      body,
      action: 'restoreResend',
      controller,
    });

    return result;
  };

  public static SendCode = (body: ISendCodeRequestModel): Promise<IResponse<ISendCodeResponseModel>> => {
    const result = Connection.POST({
      body,
      action: 'code',
      controller,
    });

    return result;
  };

  public static Guest = (): Promise<IResponse<ILoginResponseModel>> => {
    const result = Connection.GET({
      action: 'guest',
      controller,
    });

    return result;
  };

  public static Verify = (body: IVerifyRequestModel): Promise<IResponse<ILoginResponseModel>> => {
    const result = Connection.POST({
      body,
      action: 'verify',
      controller,
    });

    return result;
  };

  public static Recovery = (body: IRecoveryRequestModel): Promise<IResponse<boolean>> => {
    const result = Connection.POST({
      body,
      action: 'recovery',
      controller,
    });

    return result;
  };
};

export default AuthController;