
import _ from 'lodash';
import React, { createContext, ReactNode, useState } from "react";

import { UserTypeEnum } from 'platform/api/user/constants/enums';
import { CompleteAccountStepsEnum } from '../constants/enums';
import { ISupplierCompleteAccountFavoriteCustomerRequestModel } from 'platform/api/supplier/models/request';
import { usePersistState } from 'platform/hooks/use-persist-state';

export interface ICompleteAccountStepsData {
    [CompleteAccountStepsEnum.UserType]?: ICompleteAccountStep<UserTypeEnum>,
    [CompleteAccountStepsEnum.BusinessType]?: ICompleteAccountStep<number>,
    [CompleteAccountStepsEnum.Category]?: ICompleteAccountStep<number[]>,
    [CompleteAccountStepsEnum.Supplier]?: ICompleteAccountStep<{
        excel?: File;
        favoriteCustomers: ISupplierCompleteAccountFavoriteCustomerRequestModel[];
    }>,
}

interface ICompleteAccountStep<Data> {
    data?: Data;
    submitted?: boolean;
};

type ChangeStepDataValueType<TStep extends CompleteAccountStepsEnum> = Partial<ICompleteAccountStepsData[TStep]>;

type ContextType = [
    ICompleteAccountStepsData,
    <TStep extends CompleteAccountStepsEnum>(stepType: TStep, state: ChangeStepDataValueType<TStep>) => void,
    () => void,
];

interface IProps {
    children: ReactNode | ReactNode[],
};

export const CompleteAccountContext = createContext<ContextType>([{}, _.noop, _.noop]);

export const CompleteAccountContextProvider = ({ children }: IProps) => {
    const [stepsData, setStepsData] = usePersistState<ICompleteAccountStepsData>('complete-account-form', {});


    const changeStepData = <TStep extends CompleteAccountStepsEnum>(stepType: TStep, value: ChangeStepDataValueType<TStep>) => {
        setStepsData({
            ...stepsData,
            [stepType]: {
                ...stepsData[stepType],
                ...value,
            }
        })
    };

    const removeStepsFromCache = () => window.sessionStorage.removeItem('complete-account-form');

    return <CompleteAccountContext.Provider value={[stepsData, changeStepData, removeStepsFromCache]}>
        {children}
    </CompleteAccountContext.Provider>
};