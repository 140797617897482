//? Service to save some settings

import { LanguageEnum } from '../constants/enums';

import English from 'assets/translations/en.json';

class Settings {
  
  public static defaultLanguage = LanguageEnum.English;

  public static get language(): LanguageEnum {
    const language = window.localStorage.getItem('language') as LanguageEnum || Settings.defaultLanguage;
    if (!language || !Object.values(LanguageEnum).includes(language)) return Settings.defaultLanguage;
    return language;
  }

  public static set language(value: LanguageEnum) {
    window.localStorage.setItem('language', value);
  }

  public static get translations() {
    switch (Settings.language) {
      default: return English;
    }
  }
}

export default Settings;