import EnvDev from '../env.dev.json';
import EnvProd from '../env.prod.json';
import EnvStaging from '../env.staging.json';

const config = {
    development: EnvDev,
    staging: EnvStaging,
    production: EnvProd,
};

const currentConfig = config[(process.env.RAZZLE_ENV || '').trim()];
const env =  currentConfig || config.development;

export default env;