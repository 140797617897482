import CustomerController from "platform/api/customer";
import { ICustomerCompleteAccountRequestModel } from "platform/api/customer/models/request";
import SupplierController from "platform/api/supplier";
import { ISupplierCompleteAccountFavoriteCustomerRequestModel, ISupplierCompleteAccountRequestModel } from "platform/api/supplier/models/request";
import { UserTypeEnum } from "platform/api/user/constants/enums";
import { formValidator } from "platform/services/validator";
import { CompleteAccountStepsEnum } from "./constants/enums";
import { ICompleteAccountStepsData } from "./contexts/complete-account";
import { favoriteCustomersFormValidations } from "./form";

export const completeAccount = (stepsData: ICompleteAccountStepsData) => {
    const selectedUserType = stepsData[CompleteAccountStepsEnum.UserType]?.data;

    return selectedUserType === UserTypeEnum.Supplier
        ? completeSupplierAccount(stepsData)
        : completeCustomerAccount(stepsData);
}

export const completeCustomerAccount = (stepsData: ICompleteAccountStepsData) => {
    const completeAccountForm = {
        businessTypeId: stepsData[CompleteAccountStepsEnum.BusinessType]?.data,
        categoryIds: stepsData[CompleteAccountStepsEnum.Category]?.data,
    } as ICustomerCompleteAccountRequestModel;

    return CustomerController.CompleteAccount(completeAccountForm);
}

export const completeSupplierAccount = (stepsData: ICompleteAccountStepsData) => {
    const supplierStepData = stepsData[CompleteAccountStepsEnum.Supplier]?.data;
    const favoriteCustomers = supplierStepData?.favoriteCustomers || [];
    const isFormValid = formValidator<ISupplierCompleteAccountFavoriteCustomerRequestModel[]>
        (favoriteCustomersFormValidations)
        (favoriteCustomers);

    if (!isFormValid) return;

    const completeAccountForm = {
        categoryIds: stepsData[CompleteAccountStepsEnum.Category]?.data,
        favoriteCustomers: favoriteCustomers.filter(item => item.name && item.email),
    } as ISupplierCompleteAccountRequestModel;
    

    if (supplierStepData?.excel) {
        const formData = new FormData();
        formData.append('favoriteCustomersFile', supplierStepData.excel);
        completeAccountForm.categoryIds.forEach(item => formData.append('categoryIds', `${item}`));

        return SupplierController.CompleteAccountFile(formData);
    }

    return SupplierController.CompleteAccount(completeAccountForm);
}