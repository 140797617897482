
/* injects from baggage-loader */
require('./style.scss');
import Steps from 'modules/complete-account/components/steps';
import { CompleteAccountStepsEnum } from 'modules/complete-account/constants/enums';
import { CompleteAccountContext } from 'modules/complete-account/contexts/complete-account';
import BusinessTypeController from 'platform/api/businessType';
import { IBusinessTypeListResponseModel } from 'platform/api/businessType/models/response';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { ReactSVG } from 'react-svg';

const BusinessTypeStep = () => {
    const [businessTypes, setBusinessTypes] = useState<IBusinessTypeListResponseModel[]>([]);
    const [stepsData, changeStepData] = useContext(CompleteAccountContext);

    const businessTypeId = stepsData[CompleteAccountStepsEnum.BusinessType]?.data;

    const setBusinessTypeId = (id: number) => changeStepData(CompleteAccountStepsEnum.BusinessType, {
        data: id,
    });

    useEffect(() => {
        (async () => {
            const userType = stepsData[CompleteAccountStepsEnum.UserType]?.data;
            const result = await BusinessTypeController.List({ userType });
            setBusinessTypes(result.data);
        })();
    }, []);

    return <>
        <Steps currentStepType={CompleteAccountStepsEnum.BusinessType} />
        
        <div className="P-complete-account-business-type">
            {businessTypes.map(item => <div
                key={item.id}
                onClick={() => setBusinessTypeId(item.id)}
                className={`${item.id === businessTypeId ? 'P-active' : ''} ${item.iconPath ? 'P-has-icon' : ''}`}
            >
                {item.iconPath && <ReactSVG src={item.iconPath} />}
                {item.name}
            </div>)}
        </div>
    </>;
};

export default BusinessTypeStep;