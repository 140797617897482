import RouteService from '../services/routes';

const PROFILE_ROUTES = (() => {
  const ADDRESSES_ROUTES = RouteService.buildRouteContext('/profile/addresses', {
    MAIN: '',
    CREATE: '/create',
    UPDATE: '/update/:id',
  });

  const ORDERS_ROUTES = RouteService.buildRouteContext('/profile/orders', {
    MAIN: '',
    DETAILS: '/details/:id',
  });

  const PRESCRIPTIONS_ROUTES = RouteService.buildRouteContext('/profile/prescriptions', {
    MAIN: '',
    CREATE: '/create',
    DECIPHERED: '/deciphered/:id',
  });

  const MY_REGISTRATIONS_ROUTES = RouteService.buildRouteContext('/my-registrations', {
    MAIN: '',
    MEDICAL_HISTORY: '/medical-history',
  });

  const PROFILE_MESSAGES_ROUTES = RouteService.buildRouteContext('/profile/messages', {
    MAIN: '',
    CONVERSATION: '/:id',
  });

  const PROFILE_PARTNER_MESSAGES_ROUTES = RouteService.buildRouteContext('/profile/partner-messages', {
    MAIN: '',
    PARTNER: '/:id',
  });

  const FAVORITES_ROUTES = RouteService.buildRouteContext('/profile/favorites', {
    MAIN: '',
    SUPPLIERS: '/suppliers',
  });

  return RouteService.buildRouteContext('/profile', {
    MAIN: '',
    ADDRESSES: ADDRESSES_ROUTES,
    SPECIAL_PRODUCTS: '/special-products',
    BONUS_CARD: '/bonus-card',
    ORDER_HISTORY: '/order-history',
    MESSAGES: PROFILE_MESSAGES_ROUTES,
    PARTNER_MESSAGES: PROFILE_PARTNER_MESSAGES_ROUTES,
    PRODUCTS: '/products',
    FAVORITES: FAVORITES_ROUTES,
    MY_COMPANY: '/my-company',
    MY_REQUESTS: '/my-requests',
    MY_ADDRESSES: '/my-addresses',
    MY_WALLET: '/my-wallet',
    NOTIFICATIONS: "/notifications",

    PRESCRIPTIONS: PRESCRIPTIONS_ROUTES,
    MY_REGISTRATIONS: MY_REGISTRATIONS_ROUTES,
  });
})();

const BLOG_ROUTES = RouteService.buildRouteContext('/blog', {
  MAIN: '',
  DETAILS: '/:id',
});

const NEWS_ROUTES = RouteService.buildRouteContext('/news', {
  MAIN: '',
  DETAILS: '/:id',
});

const TENDERS_ROUTES = RouteService.buildRouteContext('/tenders', {
  MAIN: '',
  DETAILS: '/:id',
});

const CLINIC_ROUTES = RouteService.buildRouteContext('/clinic', {
  MAIN: '',
  DOCTORS: '/doctors',
  LABORATORY: '/laboratory',
  PRICE_LIST: '/price-list',
  ABOUT_US: '/about-us',
});

const PRODUCTS_ROUTES = RouteService.buildRouteContext('/products', {
  MAIN: '',
  SIMILAR: '/similar/:id',
  DETAILS: '/details/:id',
  CREATE: '/create',
  EDIT: '/edit/:id',
});

const MESSAGES_ROUTES = RouteService.buildRouteContext('/messages', {
  MAIN: '',
  CONVERSATION: '/:id',
});

const WISH_LIST_ROUTES = RouteService.buildRouteContext('/wish-list', {
  MAIN: '',
  INVITATION: '/invitation/:code',
});

const AUTH_ROUTES = RouteService.buildRouteContext('/auth', {
  MAIN: '',
  SIGN_UP: '/sign-up',
  VERIFICATION: '/verification',
  FORGOT_PASSWORD: '/forgot-password',
  RESTORE_PASSWORD: '/restore-password',
});

const COMPLETE_ACCOUNT_ROUTES = RouteService.buildRouteContext('/complete-account', {
  MAIN: '',
  BUSINESS_TYPE: '/business-type',
  CATEGORY: '/category',
  SUPPLIER: '/supplier',
});

const SUPPLIER_ROUTES = RouteService.buildRouteContext('/suppliers', {
  MAIN: '',
  DETAILS: '/details/:id',
});

const PARTNERS_ROUTES = RouteService.buildRouteContext('/partners', {
  MAIN: '',
  PRODUCTS: '/products/:id',
});

const CUSTOMERS_ROUTES = RouteService.buildRouteContext('/customers', {
  MAIN: '',
  DETAILS: '/details/:id',
});

const DISCOUNTS_ROUTES = RouteService.buildRouteContext('/discounts', {
  MAIN: '',
  CREATE: '/create',
  CREATE_SECOND_STEP: '/create-second-step',
  EDIT: '/edit/:id',
  EDIT_SECOND_STEP: '/edit-second-step/:id',
});

const LANDING_ROUTES = RouteService.buildRouteContext('/landing', {
  BUYER: '',
  SUPPLIER: '/supplier',
});

const ROUTES = {
  HOME: '/',
  FAQ: '/faq',
  CART: '/cart',
  CHECKOUT: '/checkout',
  ABOUT_US: '/about-us',
  VACANCIES: '/vacancies',
  HOW_TO_ORDER_ONLINE: '/how-to-order-online',
  CONTACT: '/contact',
  BUSINESS: '/business',
  CONTRACT: '/contract',
  SERVICES: '/services',
  CLINIC: CLINIC_ROUTES,
  PHARMACIES: '/pharmacies',
  CARD_INFO: '/card-info',
  EXCHANGE_RETURN: '/exchange-return',
  WHOLESALE_NEWS: '/wholesale-news',
  WHOLESALE_PROMOTIONS: '/wholesale-promotions',
  CONFIDENTIALITY_CONDITIONS: '/conditions',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_USE: '/terms-of-use',

  BLOG: BLOG_ROUTES,
  NEWS: NEWS_ROUTES,
  TENDERS: TENDERS_ROUTES,
  PROFILE: PROFILE_ROUTES,
  WISH_LIST: WISH_LIST_ROUTES,
  
  MAIN: '/',
  BASKET: '/basket',
  ORDERS: '/orders',

  AUTH: AUTH_ROUTES,
  COMPLETE_ACCOUNT: COMPLETE_ACCOUNT_ROUTES,
  PARTNERS: PARTNERS_ROUTES,
  SUPPLIERS: SUPPLIER_ROUTES,
  PRODUCTS: PRODUCTS_ROUTES,
  CUSTOMERS: CUSTOMERS_ROUTES,
  DISCOUNTS: DISCOUNTS_ROUTES,
  MESSAGES: MESSAGES_ROUTES,
  LANDING : LANDING_ROUTES,
}

export default ROUTES;