import React, { lazy, Suspense, useContext, useMemo } from 'react';

import { UserContext } from 'contexts/user';
import { UserTypeEnum } from 'platform/api/user/constants/enums';

const CustomerWorkspace = lazy(() => import(
    /* webpackChunkName: "customer" */
    /* webpackMode: "lazy" */
    'workspaces/customer'
));

const SupplierWorkspace = lazy(() => import(
    /* webpackChunkName: "supplier" */
    /* webpackMode: "lazy" */
    'workspaces/supplier'
));

const LandingWorkspace = lazy(() => import(
    /* webpackChunkName: "landing" */
    /* webpackMode: "lazy" */
    'workspaces/landing'
));

const Layout = () => {
    const [account] = useContext(UserContext);

    const Workspace = useMemo(() => {
        switch (account?.type) {
            case UserTypeEnum.Supplier: return SupplierWorkspace;
            case UserTypeEnum.Customer: return CustomerWorkspace;
            default: return LandingWorkspace;
        }
    }, [account?.type]);

    return <>
        <Suspense fallback="">
            <Workspace />
        </Suspense>
    </>
};

export default Layout;