import { UserTypeEnum } from "platform/api/user/constants/enums";
import ROUTES from "platform/constants/routes";
import { CompleteAccountStepsEnum } from "./enums";

export const CompleteAccountStepRoutes = {
    [CompleteAccountStepsEnum.UserType]: ROUTES.COMPLETE_ACCOUNT.MAIN,
    [CompleteAccountStepsEnum.BusinessType]: ROUTES.COMPLETE_ACCOUNT.BUSINESS_TYPE,
    [CompleteAccountStepsEnum.Category]: ROUTES.COMPLETE_ACCOUNT.CATEGORY,
    [CompleteAccountStepsEnum.Supplier]: ROUTES.COMPLETE_ACCOUNT.SUPPLIER,
};

export const CompleteAccountStepsViewEnum = {
    [CompleteAccountStepsEnum.UserType]: 'user_type',
    [CompleteAccountStepsEnum.BusinessType]: 'business_type',
    [CompleteAccountStepsEnum.Category]: 'category',
};

export const CustomerCompleteAccountSteps = [
    {
        name: 'user_type_step',
        type: CompleteAccountStepsEnum.UserType,
    },
    {
        name: 'business_type_step',
        type: CompleteAccountStepsEnum.BusinessType,
    },
    {
        name: 'category_step',
        type: CompleteAccountStepsEnum.Category,
    }
];

export const SupplierCompleteAccountSteps = [
    {
        name: 'user_type_step',
        type: CompleteAccountStepsEnum.UserType,
    },
    {
        name: 'category_step',
        type: CompleteAccountStepsEnum.Category,
    },
    {
        name: 'supplier_step',
        type: CompleteAccountStepsEnum.Supplier,
    }
];