
/* injects from baggage-loader */
require('./style.scss');
import React, { memo, useEffect, useContext } from 'react';
import useTranslations from 'platform/hooks/use-translations';
import useForm from 'platform/hooks/use-form';
import { formValidations, initialForm } from './form';
import AuthController from 'platform/api/auth';
import { Link, useHistory } from 'react-router-dom';
import ROUTES from 'platform/constants/routes';
import { AuthContext } from 'contexts/auth';
import { IRegisterRequestModel } from 'platform/api/auth/models/request';
import FormField from 'components/form-field';
import useQueryParams from 'platform/hooks/use-query-params';
import AlertModals from 'platform/services/alertModals';

const passwordInputProps = {
    type: 'password',
    autoComplete: 'new-password',
};

interface IQueryParams { email: string; }

const SignUp = () => {
    const history = useHistory();
    const [, setCredientals] = useContext(AuthContext);
    const { form, setForm, buildField, submitted, onSubmit, formValid } = useForm<IRegisterRequestModel>(initialForm, formValidations);
    const texts = useTranslations('auth');

    const [queryParams, ] = useQueryParams<IQueryParams>({
        email: '',
    });

    useEffect(() => {
        queryParams.email && setForm(formState => ({ ...formState, email: queryParams.email }));
    }, [queryParams]);

    useEffect(() => {
        (async () => {
            if (submitted && formValid) {
                const result = await AuthController.Register(form);

                if (result.data) {
                    setCredientals(result.data);
                    history.push(form.isByAdmin ? ROUTES.COMPLETE_ACCOUNT.MAIN : ROUTES.AUTH.VERIFICATION);
                }
            }
        })();
    }, [submitted]);

    return <>
        <h2 className="P-title">{texts.sign_up_title}</h2>
        <p className="P-description">{texts.sign_up_description}</p>

        <form autoComplete="off" className="G-form P-sign-up-page-form" onSubmit={onSubmit}>
            <div>
                <FormField half title={texts.sign_up_field_first_name} {...buildField('firstName')} />
                <FormField half title={texts.sign_up_field_last_name} {...buildField('lastName')} />
                <FormField half title={texts.sign_up_field_email} {...buildField('email')} />
                <FormField half title={texts.sign_up_field_phone_number} {...buildField('phoneNumber')} />
                <FormField half title={texts.sign_up_field_business_name} {...buildField('businessName')} />
                <FormField half title={texts.sign_up_field_registration_number} {...buildField('companyRegistrationNumber')} />
            </div>

            <div>
                <FormField half title={texts.sign_up_field_address} {...buildField('addressText')} />
                <FormField half title={texts.sign_up_field_city} {...buildField('city')} />
                <FormField half title={texts.sign_up_field_country} {...buildField('country')} />
                <FormField half title={texts.sign_up_field_postal_code} {...buildField('postalCode')} />
            </div>

            <div>
                <FormField half={!form.isByAdmin} title={texts.sign_up_field_password} {...buildField('password', passwordInputProps)} />
                {!form.isByAdmin && <FormField half title={texts.sign_up_field_confirm_password} {...buildField('confirmPassword', passwordInputProps)} />}
            </div>

            <button className="G-button">{texts.sign_up_submit}</button>
        </form>

        <p className="P-bottom-text">Have an account? <Link to={ROUTES.AUTH.MAIN}>Sign In</Link></p>
    </>
};

export default memo(SignUp);