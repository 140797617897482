import Settings from "./settings";

import English from 'assets/translations/en.json';

class Translations {
    public static getBlock(key: string) {
        switch (Settings.language) {
            default: return English[key];
        }
    }
}

export default Translations;