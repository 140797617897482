
/* injects from baggage-loader */
require('./style.scss');
import Steps from 'modules/complete-account/components/steps';
import { CompleteAccountStepsEnum } from 'modules/complete-account/constants/enums';
import { CompleteAccountContext } from 'modules/complete-account/contexts/complete-account';
import CategoryController from 'platform/api/category';
import { ICategoryListResponseModel } from 'platform/api/category/models/response';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { ReactSVG } from 'react-svg';

const CategoryStep = () => {
    const [categories, setCategories] = useState<ICategoryListResponseModel[]>([]);
    const [stepsData, changeStepsData] = useContext(CompleteAccountContext);

    const categoryIds = stepsData[CompleteAccountStepsEnum.Category]?.data || [];

    const setCategoryId = (id: number) => {
        const changedCategoryIds = [...categoryIds];

        const existingIndex = categoryIds.indexOf(id);
        if (~existingIndex) changedCategoryIds.splice(existingIndex, 1);
        else changedCategoryIds.push(id);

        changeStepsData(CompleteAccountStepsEnum.Category, {
            data: changedCategoryIds,
        });
    }

    useEffect(() => {
        (async () => {
            const result = await CategoryController.List();
            setCategories(result.data);
        })();
    }, []);

    return <>
        <Steps currentStepType={CompleteAccountStepsEnum.Category} />

        <div className="P-complete-account-category">
            {categories.map(item => <div
                key={item.id}
                onClick={() => setCategoryId(item.id)}
                className={`${categoryIds.includes(item.id) ? 'P-active' : ''} ${item.iconPath ? 'P-has-icon' : ''}`}
            >
                {item.iconPath && <ReactSVG src={item.iconPath} />}
                {item.name}
            </div>)}
        </div>
    </>;
};

export default CategoryStep;