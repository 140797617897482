import enviroment from 'enviroment';
import nodeFetch from 'node-fetch';
import Settings from './settings';
import { OSTypeEnum, LanguageEnum } from '../constants/enums';
import { IRequest, IBodyRequest, IResponse } from '../constants/interfaces';
import AuthService from './auth';

window.abortableRequests = window.abortableRequests || [];

class Connection {

  private static queryFromObject = (obj: object): string => {
    const str: string[] = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p) && (obj[p] !== null && obj[p] !== undefined && obj[p] !== '')) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }

    return str.join("&");
  }

  private static createHeaders = (isUpload: boolean): Headers => {
    const HEADERS = new Headers();

    AuthService.isAuthorized ? HEADERS.append('Authorization', `Bearer ${AuthService.credientals?.accessToken}`) :

    HEADERS.append('Language', Settings.language.toString());
    HEADERS.append('OsType', OSTypeEnum.Web.toString());
    !isUpload && HEADERS.append('Content-Type', 'application/json');

    return HEADERS;
  }


  private static responseRestructure = (response: Response): Promise<any> => {
    if (response.status === 401 || response.status === 403) {
      AuthService.logout();
      window.location.reload();
    }

    return new Promise(async resolve => {
      const alertify = await import('alertifyjs');
      const contentType = response.headers.get("content-type");

      if (contentType?.includes("application/json") || contentType?.includes("application/problem+json")) {
        response.json().then((result: IResponse<any>) => {
          let errorMessage: string = '';

          if (response.status === 400) errorMessage = Object
            .values(result.errors || {})
            .map(item => item?.[0] || '')
            .join(', ');
          else if (!result.success) errorMessage = result.message;

          if (errorMessage) {
            alertify.dismissAll();
            alertify.error(errorMessage);
          }

          resolve(result);
        });

      } else response.text().then(resolve);
    });
  }

  //? Kill all pending requests (abort)
  public static AbortAll = (): void => window.abortableRequests?.forEach(item => item.abort());

  public static POST = async <Body>(data: IBodyRequest<Body>): Promise<any> => {
    const abort = new AbortController();
    const { controller, action, body, query, noneJSONBody } = data;
    const onlyQuery: boolean = (!action && query) as boolean;
    const HEADERS = Connection.createHeaders(noneJSONBody as boolean);

    !data.unabortable && window.abortableRequests.push(abort);

    try {
      const response: Response = await fetch(`${enviroment.BASE_API_URL}/api/${controller}${!onlyQuery ? '/' : ''}${action}${query ? `?${Connection.queryFromObject(query)}` : ''}`, {
        body: noneJSONBody ? body as any : JSON.stringify(body),
        method: 'POST',
        headers: HEADERS,
        signal: abort.signal,
      });

      return Connection.responseRestructure(response);
    } catch (e) {
      console.log(e);
      return { aborted: true };
    }
  }

  public static PUT = async <Body>(data: IBodyRequest<Body>): Promise<any> => {
    const abort = new AbortController();
    const { controller, action, body, query, noneJSONBody } = data;
    const onlyQuery: boolean = (!action && query) as boolean;
    const HEADERS = Connection.createHeaders(noneJSONBody as boolean);

    !data.unabortable && window.abortableRequests.push(abort);

    try {
      const response: Response = await fetch(`${enviroment.BASE_API_URL}/api/${controller}${!onlyQuery ? '/' : ''}${action}${query ? `?${Connection.queryFromObject(query)}` : ''}`, {
        body: noneJSONBody ? body as any : JSON.stringify(body),
        method: 'PUT',
        headers: HEADERS,
        signal: abort.signal,
      });

      return Connection.responseRestructure(response);
    } catch (e) {
      return { aborted: true };
    }
  }

  public static DELETE = async <Body>(data: IBodyRequest<Body>): Promise<any> => {
    const abort = new AbortController();
    const { controller, action, body, query, noneJSONBody } = data;
    const onlyQuery: boolean = (!action && query) as boolean;
    const HEADERS = Connection.createHeaders(noneJSONBody as boolean);

    !data.unabortable && window.abortableRequests.push(abort);

    try {
      const response: Response = await fetch(`${enviroment.BASE_API_URL}/api/${controller}${!onlyQuery ? '/' : ''}${action}${query ? `?${Connection.queryFromObject(query)}` : ''}`, {
        body: noneJSONBody ? body as any : JSON.stringify(body),
        method: 'DELETE',
        headers: HEADERS,
        signal: abort.signal,
      });

      return Connection.responseRestructure(response);
    } catch (e) {
      return { aborted: true };
    }
  }

  public static GET = async (data: IRequest): Promise<any> => {
    const abort = new AbortController();
    const { controller, action, query } = data;
    const onlyQuery = !action && query;
    const HEADERS = Connection.createHeaders(false);

    !data.unabortable && window.abortableRequests.push(abort);

    try {
      const response = await fetch(`${enviroment.BASE_API_URL}/api/${controller}${!onlyQuery ? '/' : ''}${action}${query ? `?${Connection.queryFromObject(query)}` : ''}`, {
        method: 'GET',
        headers: HEADERS,
        signal: abort.signal,
      });

      return Connection.responseRestructure(response);
    } catch (e) {
      return { aborted: true };
    }
  }

  //? Request Helper for server side
  public static ServerRequest = async ({ method, controller, action, body, query }: {
    method: string;
    controller: string;
    action: string;
    body?: any;
    query: { [key: string]: any },
  }) => {
    const onlyQuery = !action && query;
    const response = await nodeFetch(`${enviroment.BASE_API_URL}/api/${controller}${!onlyQuery ? '/' : ''}${action}${query ? `?${Connection.queryFromObject(query)}` : ''}`, {
      body,
      headers: { language: LanguageEnum.English.toString() },
      method,
    });

    return response.ok ? response.json() : {};
  }
}

export default Connection;
