
/* injects from baggage-loader */
require('./style.scss');
import React, { memo, useEffect, useContext } from 'react';
import useTranslations from 'platform/hooks/use-translations';
import useForm from 'platform/hooks/use-form';
import { ILoginRequestModel } from 'platform/api/auth/models/request';
import { initialForm } from './form';
import AuthController from 'platform/api/auth';
import { Link, useHistory } from 'react-router-dom';
import ROUTES from 'platform/constants/routes';
import { AuthContext } from 'contexts/auth';
import FormField from 'components/form-field';

const SignIn = () => {
    const history = useHistory();
    const [, setCredientals] = useContext(AuthContext);
    const { form, buildField, submitted, onSubmit, formValid } = useForm<ILoginRequestModel>(initialForm);

    const authTexts = useTranslations('auth');

    useEffect(() => {
        (async () => {
            if (submitted && formValid) {
                const result = await AuthController.Login(form);
                if (result.data) {
                    setCredientals(result.data);
                    history.push(result.data.isAccountComplete ? '' : ROUTES.COMPLETE_ACCOUNT.MAIN);
                }
            }
        })()
    }, [submitted]);

    return <>
        <h2 className="P-title">{authTexts.sign_in_title}</h2>

        <form className="G-form P-sign-in-page-form" onSubmit={onSubmit}>
            <FormField title={authTexts.sign_in_field_username} {...buildField('username')} />
            <FormField title={authTexts.sign_in_field_password} {...buildField('password', { type: 'password' })} />

            <button className="G-button">{authTexts.sign_in_submit}</button>

            <div className="P-bottom-block">
                <Link className="P-forgot-password" to={ROUTES.AUTH.FORGOT_PASSWORD}>{authTexts.forgot_password_title}</Link>
            </div>
        </form>

        {/* <p className="P-bottom-text">Don’t have an account? <Link to={ROUTES.AUTH.SIGN_UP}>Sign Up</Link></p> */}
    </>
};

export default memo(SignIn);