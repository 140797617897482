
/* injects from baggage-loader */
import React, { memo, useEffect } from 'react';
import useTranslations from 'platform/hooks/use-translations';
import useForm from 'platform/hooks/use-form';
import { IForgotPasswordRequestModel } from 'platform/api/auth/models/request';
import { initialForm } from './form';
import AuthController from 'platform/api/auth';
import { Link, useHistory } from 'react-router-dom';
import ROUTES from 'platform/constants/routes';
import FormField from 'components/form-field';
import useQueryParams from 'platform/hooks/use-query-params';

interface IQueryParams { email?: string; }

const ForgotPassword = () => {
    const history = useHistory();
    const [{ email: queryEmail }] = useQueryParams<IQueryParams>({});
    const { form, setForm, buildField, submitted, onSubmit, formValid } = useForm<IForgotPasswordRequestModel>(initialForm);

    const authTexts = useTranslations('auth');

    useEffect(() => {
        queryEmail && setForm({ ...form, username: queryEmail });
    }, [queryEmail]);

    useEffect(() => {
        (async () => {
            if (submitted && formValid) {
                const result = await AuthController.ForgotPassword(form);
                if (result.success) {
                    history.push(`${ROUTES.AUTH.RESTORE_PASSWORD}?email=${form.username}`);
                    const alertify = await import('alertifyjs');
                    alertify.success(authTexts.forgot_password_sent);
                }
            }
        })();
    }, [submitted]);

    return <>
        <h2 className="P-title">{authTexts.forgot_password_title}</h2>
        <p className="P-description">{authTexts.forgot_password_description}</p>

        <form className="G-form" onSubmit={onSubmit}>
            <FormField title={authTexts.forgot_password_field_username} {...buildField('username')} />

            <button className="G-button">{authTexts.forgot_password_submit}</button>
        </form>

        <p className="P-bottom-text">Did you remember your password? <Link to={ROUTES.AUTH.MAIN}>Sign In</Link></p>
    </>
};

export default memo(ForgotPassword);