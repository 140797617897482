import classNames from 'classnames';
import React, { HTMLAttributes, useCallback } from 'react';
import NumberInput from './number-input';

interface IProps {
    title: string;
    inputProps: HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>;
    half?: boolean;
    quarter?: boolean;
    third?: boolean;
    onlyInt?: boolean;
    onlyNumber?: boolean;
    errored?: boolean;
    textarea?: boolean;
    readOnly?: boolean;
    wrapperClassName?: string;
};

const FormField = ({
    title,
    half,
    quarter,
    third,
    errored,
    wrapperClassName,
    inputProps,
    onlyInt,
    onlyNumber,
    textarea,
}: IProps) => {
    const className = classNames({
        'G-form-field': !half && !quarter && !third,
        'G-form-half-field': half,
        'G-form-quarter-field': quarter,
        'G-form-third-field': third,
        'G-form-field-invalid': errored,
    }, wrapperClassName);

    const fieldClassName = classNames({
        'G-main-input': !textarea,
        'G-main-textarea': textarea,
    }, inputProps.className);

    const fieldContentProps = { ...inputProps, className: fieldClassName }

    let fieldContent = <input {...fieldContentProps } />;
    
    if (onlyNumber) fieldContent = <NumberInput {...fieldContentProps} />;
    else if (onlyInt) fieldContent = <NumberInput {...fieldContentProps} int />;
    else if (textarea) fieldContent = <textarea {...fieldContentProps} />;

    return <label className={className}>
        <p>{title}</p>
        {fieldContent}
    </label>
};

export default FormField;