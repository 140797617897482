import Connection from '../../services/connection';
import { IPagingResponse, IResponse } from '../../constants/interfaces';
import { ICustomerCompleteAccountRequestModel, ICustomerListRequestModel } from './models/request';
import { ICustomerListResponseModel, ICustomerDetailsResponseModel } from './models/response';
import { ILoginResponseModel } from '../auth/models/response';

const controller = 'customer';

class CustomerController {

  public static ListForSupplier = (body: ICustomerListRequestModel): Promise<IResponse<IPagingResponse<ICustomerListResponseModel>>> => {
    const result = Connection.POST({
      action: 'listForSupplier',
      controller,
      body,
    });

    return result;
  };

  public static DetailsForSupplier = (id: number): Promise<IResponse<ICustomerDetailsResponseModel>> => {
    const result = Connection.GET({
      action: `detailsForSupplier/${id}`,
      controller,
    });

    return result;
  };

  public static CompleteAccount = (body: ICustomerCompleteAccountRequestModel): Promise<IResponse<ILoginResponseModel>> => {
    const result = Connection.PUT({
      body,
      action: 'completeAccount',
      controller,
    });

    return result;
  };
};

export default CustomerController;