import _ from "lodash";
import { hasValueValidator } from "platform/services/validator";
import { useCallback, useMemo } from "react";

export type FormValidationPattern<Form extends object | object[]> = Form extends object[]
  ? { [key in keyof Partial<Form[number]>]: (value: Form[number][key], form: Form) => boolean }
  : { [key in keyof Partial<Form>]: (value: Form[key], form: Form) => boolean };

export type FormErrors<Form extends object | object[]> = Form extends object[]
  ? { [key in keyof Form]: boolean }[]
  : { [key in keyof Form]: boolean };

const useFormValidator = <Form extends object | object[]>(form: Form, validationPattern?: FormValidationPattern<Form>) => {
  const isPropertyInvalid = useCallback((formIndex: number | undefined, value: Form[keyof Form], key: string) => {
    const currentPattern = validationPattern?.[key] || hasValueValidator;
    const isInvalid = !currentPattern(value, form, formIndex);
    return isInvalid;
  }, [form]);

  const getErrors = (currentForm: Form, currentFormIndex?: number) => {
    const validatorFunc = _.partial(isPropertyInvalid, currentFormIndex);
    return _.mapValues<Form, boolean>(currentForm, validatorFunc);
  };

  const errors = useMemo(() => {
    if (Array.isArray(form)) return form.map(getErrors) as FormErrors<Form>;
    return getErrors(form) as FormErrors<Form>;
  }, [isPropertyInvalid]);

  const valid = useMemo(() => {
    if (Array.isArray(errors)) return errors.every(item => !Object.values(item).some(Boolean));
    return !Object.values(errors).some(Boolean);
  }, [errors]);

  return {
    errors,
    valid,
  };
}

export default useFormValidator;