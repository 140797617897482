import { ISupplierCompleteAccountFavoriteCustomerRequestModel } from "platform/api/supplier/models/request";
import { composeValidatorsOR, emailValidator, hasValueValidator } from "platform/services/validator";

export const initialFavoriteCustomersForm = {
    name: '',
    email: '',
};

const acceptEmpty = (
    value: string,
    form: ISupplierCompleteAccountFavoriteCustomerRequestModel[],
    formIndex: number
) => !form[formIndex].name && !form[formIndex].email;

export const favoriteCustomersFormValidations = {
    name: composeValidatorsOR(acceptEmpty, hasValueValidator),
    email: composeValidatorsOR(acceptEmpty, emailValidator),
};