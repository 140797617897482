import Connection from '../../services/connection';
import { IResponse } from '../../constants/interfaces';
import { IUserInviteRequestModel, IUserModifyRequestModel, IUserRequestRegisterRequestModel } from './models/request';
import { IUserResponseModel } from './models/response';

class UserController {

  private static controller = 'user';

  public static Get = (): Promise<IResponse<IUserResponseModel>> => {
    const result = Connection.GET({
      action: '',
      controller: UserController.controller,
    });

    return result;
  };

  public static UploadImage = (body: FormData): Promise<IResponse<string>> => {
    const result = Connection.POST({
      body,
      action: 'image',
      controller: UserController.controller,
      noneJSONBody: true,
    });

    return result;
  };

  public static Update = (body: IUserModifyRequestModel): Promise<IResponse<IUserResponseModel>> => {
    const result = Connection.PUT({
      body,
      action: '',
      controller: UserController.controller,
    });

    return result;
  };

  public static RequestRegister = (body: IUserRequestRegisterRequestModel): Promise<IResponse<boolean>> => {
    const result = Connection.POST({
      body,
      action: 'requestRegister',
      controller: UserController.controller,
    });

    return result;
  };

  public static Invite = (body: IUserInviteRequestModel): Promise<IResponse<boolean>> => {
    const result = Connection.POST({
      body,
      action: 'invite',
      controller: UserController.controller,
    });

    return result;
  };
};

export default UserController;
