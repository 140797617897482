import _ from "lodash";
import { FormErrors, FormValidationPattern } from "platform/hooks/use-form-validator";

export const onlyNumbersWithoutCode = (str: string) => {
  const reg = /^\+?[0-9]*$/;

  return reg.test(str) && str.length >= 8;
}

export const isValidEmail = (value?: string | null): boolean => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const beeline = '91|99|96|43|33';
  const ucom = '55|95|41|44|60';
  const mts = '93|94|77|49|98';
  const Mobregex = new RegExp(`^(374)((?:${mts}|${beeline}|${ucom})([0-9]{6}))$`);
  if (!value) return false;
  return regex.test(value) || Mobregex.test(value);
}

export const isValidPassword = (value?: string | null): boolean => {
  if (!value) return false;
  return value.length >= 6;
}

export const isValidDateRange = (from?: string | Date, to?: string | Date, optional = true) => {
  const onlyTo = !from && to;
  const onlyFrom = !to && from;

  if (optional && (onlyTo || onlyFrom)) return true;

  if (from && to) {
    const fromDate = new Date(from);
    const toDate = new Date(to);

    return fromDate <= toDate;
  }

  return false;
}

export const isValidNonCityPhone = (value?: string | null): boolean => {
  if (!value && value !== '') return false;
  const beeline = '91|99|96|43|33';
  const ucom = '55|95|41|44|45';
  const mts = '93|94|77|49|98';
  const regex = new RegExp(`^(374)((?:${mts}|${beeline}|${ucom})([0-9]{6}))$`);
  return regex.test(value);
};

export const isValidPhone = (value?: string | null): boolean => {
  const beeline = '91|99|96|43|33';
  const ucom = '55|95|41|44|45';
  const mts = '93|94|77|49|98';

  if (!value && value !== '') return false
  const regex = new RegExp(`^(374)?((?:${mts}|${beeline}|${ucom})([0-9]{6}))$`);
  return regex.test(value);
};

export const isValidPhoneOnly = (value?: any | null) => {
  if (value.length > 12 || value.length < 9) return false;
  return value;
};

export const phoneFieldPattern = "[0-9]{1,8}";
export const promoCodeFieldPattern = "[A-Za-z0-9]{1,8}";

type ValidatorFunc = (value: string, form: unknown, formIndex?: number) => boolean;

export const composeValidators = (...validators: ValidatorFunc[]): ValidatorFunc => (
  (...args) => validators.every(valid => valid(...args))
);

export const composeValidatorsOR = (...validators: ValidatorFunc[]): ValidatorFunc => (
  (...args) => validators.some(valid => valid(...args))
);

//#region Validators

export const emptyValidator = () => true; //noop

export const hasValueValidator = (value: string | number) => !!value || value === 0;

export const optionalValidator = (value: string) => !value; // compose it with other validators

export const minLengthValidator = <Type>(length: number) => (
  (value: string | Type[]) => (value || '').length >= length
);

export const formPropertyValidator = <TForm extends object | object[]>(
  validationPattern: FormValidationPattern<TForm>,
  form: TForm,
  formIndex: number | undefined,
  value: TForm[keyof TForm],
  key: string,
) => {
  const currentPattern = validationPattern?.[key] || hasValueValidator;
  const isValid = currentPattern(value, form, formIndex);
  return isValid;
};

export const formPropertyInvalidValidator = <TForm extends object | object[]>(
  validationPattern: FormValidationPattern<TForm>,
  form: TForm,
  formIndex: number | undefined,
  value: TForm[keyof TForm],
  key: string,
) => {
  const currentPattern = validationPattern?.[key] || hasValueValidator;
  const patternResult = currentPattern(value, form, formIndex);
  const isInvalid = typeof patternResult === 'object' ? patternResult : !currentPattern(value, form, formIndex);
  return isInvalid;
};

export const formValidator = <TForm extends object | object[]>(validationPattern: FormValidationPattern<TForm>) => (
  (form: TForm) => {
    if (Array.isArray(form)) {
      return form.every((item, index) => {
        const isPropertyValidArrForm = _.partial(formPropertyValidator, validationPattern, form, index);
        const validParams = _.mapValues(item, isPropertyValidArrForm);
        return Object.values(validParams).every(Boolean);
      });
    }

    const isPropertyValid = _.partial(formPropertyValidator, validationPattern, form, undefined);
    const validProperties = _.mapValues(form, isPropertyValid);
    return Object.values(validProperties).every(Boolean);
  }
);

export const UKPhoneValidator = (value: string) => {
  const regex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/;
  return regex.test(value);
}

export const emailValidator = (value: string) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(value);
};

export const onlyDigits = (value: string) => {
  const regex = /^[0-9]/;
  return regex.test(value);
}

export const passwordValidator = composeValidators(
  minLengthValidator(6),
);

//#endregion Validators