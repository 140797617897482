
/* injects from baggage-loader */
require('./style.scss');
import React from 'react';

import Modal from "components/modal";
import useDataFetch from 'platform/hooks/use-data-fetch';
import StaticMediaController from 'platform/api/staticMedia';

interface IProps {
    onClose(): void;
}

export const PrivacyPolicy = ({ onClose }: IProps) => {
    const { data: url } = useDataFetch(StaticMediaController.PrivacyPolicy, []);

    return <Modal className="P-privacy-policy-modal" onClose={onClose}>
        {url && <object data={`${url}#toolbar=0`} />}
    </Modal>
};

export default PrivacyPolicy;