import AuthService from "./auth";
import UserService from "./user";

export const oneOfGuards = (...guards: (() => boolean)[]) => (
    () => guards.some(valid => valid())
);

export const composeGuards = (...guards: (() => boolean)[]) => (
    () => guards.every(valid => valid())
);

//#region Validators

export const authorizedGuard = () => AuthService.isAuthorized;
export const userConfigGuard = (cb: (config: Record<string, any>) => boolean) => () => (
    UserService.account
        ? cb(UserService.getConfig(UserService.account))
        : false
);

export const canAuthorizeGuard = () => !AuthService.isAccountVerified || !AuthService.isAccountComplete;
export const accountNotCompleteGuard = () => AuthService.isAuthorized && !AuthService.isAccountComplete;

//#endregion Validators