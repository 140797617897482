import Connection from '../../services/connection';
import { IResponse } from '../../constants/interfaces';
import { IBusinessTypeListResponseModel } from './models/response';
import { IBusinessTypeListRequestModel } from './models/request';

class BusinessTypeController {

  private static controller = 'businessType';

  public static List = (query: IBusinessTypeListRequestModel): Promise<IResponse<IBusinessTypeListResponseModel[]>> => {
    const result = Connection.GET({
      query,
      action: '',
      controller: BusinessTypeController.controller,
    });

    return result;
  };
};

export default BusinessTypeController;