import { browserHistory } from "app";
import { ILoginResponseModel } from "platform/api/auth/models/response";
import ROUTES from "platform/constants/routes";
import UserService from "./user";

class AuthService {

    public static set credientals(authData: ILoginResponseModel | null) {
        if (authData) window.localStorage.setItem('authData', JSON.stringify(authData))
        else {
            UserService.account = null;
            window.localStorage.removeItem('authData');
        }
    }

    public static get credientals(): ILoginResponseModel | null {
        try {
            const authData = window.localStorage.getItem('authData');
            return authData ? JSON.parse(authData) : null;
        } catch (e) {
            AuthService.credientals = null;
            return null;
        }
    }

    public static get isAuthorized() {
        return !!AuthService.credientals?.accessToken;
    }

    public static get isAccountComplete() {
        return AuthService.credientals?.isAccountComplete;
    }

    public static get isAccountVerified() {
        return AuthService.credientals?.isAccountVerified;
    }

    public static logout(redirectPath?: string) {
        AuthService.credientals = null;
        UserService.account = null;
        browserHistory.push(redirectPath || ROUTES.AUTH.MAIN);
    }
}

export default AuthService;