
/* injects from baggage-loader */
require('./style.scss');
import React, { forwardRef, memo, MutableRefObject, useState } from 'react';

import Modal from '../modal';
import useAsyncPrompt from 'platform/hooks/use-async-prompt';
import useTranslations from 'platform/hooks/use-translations';

interface IPromptData {
  text?: string;
  onCancel?(): void;
  onConfirm?(): void;
};

const ConfirmModalComponent = ({ }, ref: MutableRefObject<Function | undefined>) => {
  const sharedTexts = useTranslations('shared');
  const [promptData, setPromptData] = useState<IPromptData | undefined>();

  const { resolve } = useAsyncPrompt<[IPromptData | undefined], null>(ref, (data?: IPromptData) => {
    setPromptData(data || {});
  });

  const close = () => {
    promptData?.onCancel?.();
    resolve?.(null);
    setPromptData(undefined);
  };

  const confirm = () => {
    promptData?.onConfirm?.();
    resolve?.(null);
    setPromptData(undefined);
  };

  return promptData ? <Modal onClose={close} closable={false} className="P-confirm-modal">
    {promptData.text || sharedTexts.are_you_sure}

    <div className="P-buttons">
      <button className="G-button P-close-button" onClick={close}>
        {sharedTexts.cancel}
      </button>

      <button className="G-button" onClick={confirm}>
        {sharedTexts.confirm}
      </button>
    </div>
  </Modal> : null;
};

const ConfirmModal = forwardRef(ConfirmModalComponent);

export default memo(ConfirmModal);