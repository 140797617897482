
import _ from 'lodash';
import React, { createContext, ReactNode, useState } from "react";

import { ILoginResponseModel } from "platform/api/auth/models/response";
import AuthService from "platform/services/auth";

type ContextType = [
    ILoginResponseModel | null,
    (value: ILoginResponseModel | null) => void
];

interface IProps {
    children: ReactNode | ReactNode[],
};

export const AuthContext = createContext<ContextType>([null, _.noop]);

export const AuthContextProvider = ({ children }: IProps) => {
    const [credientals, setCredientals] = useState(AuthService.credientals);

    const changeCredientals = (value: ILoginResponseModel | null) => {
        AuthService.credientals = value;
        setCredientals(value);
    };

    return <AuthContext.Provider value={[credientals, changeCredientals]}>
        {children}
    </AuthContext.Provider>
};