
/* injects from baggage-loader */
require('./style.scss');
import React, { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { CompleteAccountStepsEnum } from "../../constants/enums";
import { CompleteAccountStepRoutes, CustomerCompleteAccountSteps, SupplierCompleteAccountSteps } from "../../constants";
import { CompleteAccountContext } from "../../contexts/complete-account";
import useTranslations from "platform/hooks/use-translations";
import { AuthContext } from "contexts/auth";
import { UserTypeEnum } from "platform/api/user/constants/enums";
import { completeAccount, completeCustomerAccount } from "modules/complete-account/helpers";
import ROUTES from "platform/constants/routes";

interface IProps {
    currentStepType: CompleteAccountStepsEnum;
    onSubmit?(): void;
};

const Steps = ({ currentStepType, onSubmit }: IProps) => {
    const history = useHistory();
    const [, setCredientals] = useContext(AuthContext);
    const [stepsData, changeStepData, removeStepsFromCache] = useContext(CompleteAccountContext);
    const completeAccountTexts = useTranslations('complete_account');

    const [
        stepsConfig,
        currentStepIndex,
    ] = useMemo(() => {
        const selectedUserType = stepsData[CompleteAccountStepsEnum.UserType]?.data;

        const steps = selectedUserType === UserTypeEnum.Supplier
            ? SupplierCompleteAccountSteps
            : CustomerCompleteAccountSteps;

        const activeStepIndex = steps.findIndex(item => item.type === currentStepType);

        return [
            steps.map((item, index) => ({
                number: index + 1,
                name: completeAccountTexts[item.name],
                route: CompleteAccountStepRoutes[item.type],
                filled: index < activeStepIndex,
            })),
            activeStepIndex,
        ]
    }, [stepsData, currentStepType]);

    const backVisible = !!currentStepIndex;
    const nextVisible = stepsData[currentStepType]?.data;

    const handleBack = () => {
        const prevStep = stepsConfig[currentStepIndex - 1];
        prevStep && history.push(prevStep.route);
    }

    const handleNext = async () => {
        const nextStep = stepsConfig[currentStepIndex + 1];

        if (nextStep) history.push(nextStep.route);
        else {
            onSubmit?.();
            const result = await completeAccount(stepsData);

            if (result?.success) {
                setCredientals(result.data);
                removeStepsFromCache();
                history.push(ROUTES.MAIN);
            }
        }
    };

    return <div className="P-complete-account-page-steps">
        {backVisible && <button className="G-ghost-button P-back" onClick={handleBack}>
            {completeAccountTexts.back_button}
        </button>}

        <div className="P-steps-list">
            {stepsConfig.map(item => <div key={item.number} className={item.filled ? 'P-filled' : ''}>
                <span>{item.number}</span>
                <p>{item.name}</p>
            </div>)}
        </div>

        {nextVisible && <button className="G-button P-next" onClick={handleNext}>
            {completeAccountTexts.next_button}
        </button>}
    </div>
};

export default Steps;