import { useMemo } from "react";

import Translations from "platform/services/translations";

const useTranslations = (...blockKeys: string[]) => {
  if (blockKeys.length > 1) {
    const blocks = useMemo(() => blockKeys.map(Translations.getBlock), []);
    return blocks;
  }
  
  const [blockKey] = blockKeys;
  const block = useMemo(() => Translations.getBlock(blockKey), []);

  return block;
}

export default useTranslations;