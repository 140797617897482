import { IUserResponseModel } from "platform/api/user/models/response";

class UserService {

    public static set account(userData: IUserResponseModel | null) {
        userData
            ? window.localStorage.setItem('userData', JSON.stringify(userData))
            : window.localStorage.removeItem('userData');
    }

    public static get account(): IUserResponseModel | null {
        try {
            const userData = window.localStorage.getItem('userData');
            return userData ? JSON.parse(userData): null;
        } catch (e) {
            UserService.account = null;
            return null;
        }
    }

    public static getConfig(user: IUserResponseModel) {
        return JSON.parse(user.configJSON || '{}');
    }
}

export default UserService;