
import { DependencyList, useRef, MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import { IResponse } from "platform/constants/interfaces";

interface IOptions {
  debounce?: boolean;
};

interface IReturnValue<TResult> {
  data: TResult | undefined;
  loadingRef: MutableRefObject<boolean>;
  makeFetch: () => void;
};

const useDataFetch = <TResult>(
  asyncCallback: () => Promise<IResponse<TResult>> | null | undefined,
  deps: DependencyList,
  { debounce }: IOptions = {},
): IReturnValue<TResult> => {
  const debouncedCallback = useCallback(_.debounce((deferResolve, cb) => {
    deferResolve(cb());
  }, 800), []);

  const [data, setData] = useState<TResult>();
  const loadingRef = useRef(true);

  const callback = useMemo(() => {
    if (!debounce) return asyncCallback;

    return () => new Promise(resolve => {
      debouncedCallback(resolve, asyncCallback);
    }) as Promise<IResponse<TResult>>;
  }, deps || [debouncedCallback, asyncCallback]);

  const makeFetch = useCallback(async () => {
    loadingRef.current = true;

    const result = await callback();
    result && setData(result.data);

    loadingRef.current = false;
  }, [callback]);

  useEffect(() => {
    makeFetch();
  }, [makeFetch]);

  return { data, loadingRef, makeFetch };
};

export default useDataFetch;