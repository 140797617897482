
/* injects from baggage-loader */
require('./style.scss');
import React, { forwardRef, memo, MutableRefObject, SyntheticEvent, useState } from 'react';

import Modal from '../modal';
import { Link } from 'react-router-dom';
import useAsyncPrompt from 'platform/hooks/use-async-prompt';
import useTranslations from 'platform/hooks/use-translations';
import { browserHistory } from 'app';

interface IPromptData {
  text?: string;
  link?: {
    path: string;
    name: string;
  };
  closeButton?: {
    onClick?(): void;
    name: string;
  };
};

const SuccessModalComponent = ({ }, ref: MutableRefObject<Function | undefined>) => {
  const sharedTexts = useTranslations('shared');
  const [promptData, setPromptData] = useState<IPromptData | undefined>();

  const { resolve } = useAsyncPrompt<[IPromptData | undefined], null>(ref, (data?: IPromptData) => {
    setPromptData(data || {});
  });

  const close = () => {
    promptData?.closeButton?.onClick?.();
    resolve?.(null);
    setPromptData(undefined);
  };

  return promptData ? <Modal onClose={close} closable={false} className="P-success-modal">
    <i className="icon-success-round" />

    {promptData.text}

    <div className="P-buttons">
      {promptData.link ? <Link to={promptData.link.path} onClick={close} className="G-button">
        {promptData.link.name}
      </Link> : <button className="G-button" onClick={close}>
        {sharedTexts.ok}
      </button>}

      {promptData.closeButton && <button className="G-button P-close-button" onClick={close}>
        {promptData.closeButton.name}
      </button>}
    </div>
  </Modal> : null;
};

const SuccessModal = forwardRef(SuccessModalComponent);

export default memo(SuccessModal);