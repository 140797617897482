
import _ from 'lodash';
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";

import { IUserResponseModel } from 'platform/api/user/models/response';
import UserService from 'platform/services/user';
import { AuthContext } from './auth';
import UserController from 'platform/api/user';
import usePrevious from 'platform/hooks/use-previous';

type ContextType = [
    IUserResponseModel | null,
    (value: IUserResponseModel | null) => void
];

interface IProps {
    children: ReactNode | ReactNode[],
};

export const UserContext = createContext<ContextType>([null, _.noop]);

export const UserContextProvider = ({ children }: IProps) => {
    const [credientals] = useContext(AuthContext);
    const prevCredientals = usePrevious(credientals);
    const [account, setAccount] = useState(UserService.account);

    const changeAccount = (value: IUserResponseModel | null) => {
        UserService.account = value;
        setAccount(value);
    };

    useEffect(() => {
        (async () => {
            const credientalsChanged = credientals && prevCredientals !== credientals;
            if (credientalsChanged || (credientals?.isAccountComplete && !account)) {
                const result = await UserController.Get();
                result.success && changeAccount(result.data);
            }
        })();
    }, [account, credientals, prevCredientals]);

    const loading = credientals?.isAccountComplete && !account;

    return <UserContext.Provider value={[account, changeAccount]}>
        {loading ? '' : children}
    </UserContext.Provider>
};