
/* injects from baggage-loader */
require('./style.scss');
import React, { useMemo } from "react";

import useTranslations from "platform/hooks/use-translations";

import logoWhiteImage from 'assets/images/logo_white.png';
import { matchPath, useLocation } from "react-router-dom";
import ROUTES from "platform/constants/routes";

const LeftBlock = () => {
    const texts = useTranslations('auth');
    const location = useLocation();

    const description = useMemo(() => {
        if (matchPath(location.pathname, ROUTES.AUTH.SIGN_UP))
            return texts.sign_up_left_block_description;

        return texts.left_block_description;
    }, [location, texts]);

    return <aside className="P-auth-left-block">
        <img src={logoWhiteImage} alt="logo_white" />
        <h2>{texts.left_block_welcome}</h2>
        <p>{description}</p>
    </aside>;
};

export default LeftBlock;