
/* injects from baggage-loader */
import * as React from 'react';
import Responsive from 'react-responsive';

// const maxScreen = 1650;
// const mediumScreen = 1440;
const smallScreen = 1220;
const tabletScreen = 781;
const mobileScreen = 664;
const smallMobileScreen = 560;

const Screen = {
  // Screens

  Desktop: (props: object) => <Responsive {...props} minWidth={smallScreen} />,
  SmallDesktop: (props: object) => <Responsive {...props} maxWidth={smallScreen} />,

  Min_Tablet: (props: object) => <Responsive {...props} minWidth={tabletScreen} />,
  Tablet: (props: object) => <Responsive {...props} maxWidth={tabletScreen} />,
  Mobile: (props: object) => <Responsive {...props} maxWidth={mobileScreen} />,
  Min_SmallMobile: (props: object) => <Responsive {...props} minWidth={smallMobileScreen} />,
  SmallMobile: (props: object) => <Responsive {...props} maxWidth={smallMobileScreen} />,

  // Ranges

  Desktop_SmallDesktop: (props: object) => <Responsive {...props} query={`(max-width: ${smallScreen}px) and (min-width: ${tabletScreen}px)`} />,
  SmallDesktop_Tablet: (props: object) => <Responsive {...props} query={`(max-width: ${smallScreen}px) and (min-width: ${tabletScreen}px)`} />,
  Tablet_Mobile: (props: object) => <Responsive {...props} query={`(max-width: ${tabletScreen}px) and (min-width: ${mobileScreen}px)`} />,
};

export default Screen;