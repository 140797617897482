import Connection from '../../services/connection';
import { IResponse } from '../../constants/interfaces';

const controller = 'staticMedia';

class StaticMediaController {

  public static PrivacyPolicy = (): Promise<IResponse<string>> => {
    const result = Connection.GET({
      action: 'privacyPolicy',
      controller,
    });

    return result;
  };
};

export default StaticMediaController;
