
/* injects from baggage-loader */
require('./style.scss');
import React, { useState } from 'react';

import useTranslations from 'platform/hooks/use-translations';
import PrivacyPolicy from 'components/privacy-policy';
import { dynamicText } from 'platform/helpers';

const year = new Date().getFullYear();

const Copyright = () => {
    const [
        privacyPolicyTexts,
        sharedTexts,
    ] = useTranslations('privacy_policy', 'shared');

    const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);

    return <footer className="P-copyright">
        <button onClick={() => setPrivacyPolicyOpen(true)}>{privacyPolicyTexts.title}</button>
        <p>&#169; {dynamicText(sharedTexts.copyright, year)}</p>

        {privacyPolicyOpen && <PrivacyPolicy onClose={() => setPrivacyPolicyOpen(false)} />}
    </footer>
};

export default Copyright;