
/* injects from baggage-loader */
import React, { memo, useContext, useEffect } from 'react';
import useTranslations from 'platform/hooks/use-translations';
import useForm from 'platform/hooks/use-form';
import { IVerificaitonRequestModel } from 'platform/api/auth/models/request';
import { initialForm } from './form';
import AuthController from 'platform/api/auth';
import FormField from 'components/form-field';
import { AuthContext } from 'contexts/auth';
import { useHistory } from 'react-router-dom';
import ROUTES from 'platform/constants/routes';

const Verification = () => {
    const history = useHistory();
    const [credientals, setCredientals] = useContext(AuthContext);
    const { form, buildField, submitted, onSubmit, formValid } = useForm<IVerificaitonRequestModel>(initialForm);

    const texts = useTranslations('auth');

    useEffect(() => {
        (async () => {
            if (submitted && formValid) {
                const request = { ...form };
                request.code = +request.code;

                const result = await AuthController.Verification(request);
                if (result.success) {
                    if (!credientals) return history.push(ROUTES.AUTH.MAIN);

                    setCredientals({ ...credientals, isAccountVerified: true });
                    history.push(credientals?.isAccountComplete ? ROUTES.MAIN : ROUTES.COMPLETE_ACCOUNT.MAIN);
                }
            }
        })();
    }, [submitted]);

    const resend = async () => {
        const result = await AuthController.Resend();

        if (result.success) {
            const alertify = await import('alertifyjs');
            alertify.success(texts.resend_code_description);
        }
    }

    return <>
        <h2 className="P-title">{texts.verification_title}</h2>
        <p className="P-description">{texts.verification_description}</p>

        <form className="G-form" onSubmit={onSubmit}>
            <FormField int title={texts.verification_field_code} {...buildField('code')} />

            <button className="G-button">{texts.verification_submit}</button>
        </form>

        <p className="P-bottom-text">You didn’t receive verification code? <a onClick={resend}>Resend</a></p>
    </>
};

export default memo(Verification);