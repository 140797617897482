import Connection from '../../services/connection';
import { IPagingResponse, IResponse } from '../../constants/interfaces';
import { SupplierDashboardPeriodType } from './constants/enums';
import { ISupplierCompleteAccountRequestModel, ISupplierListRequestModel, ISupplierUpdateDeliveryRequestModel, ISupplierUpdateOrderSettingsRequestModel } from './models/request';
import {
  ISupplierDeliveryResponseModel,
  ISupplierDetailsResponseModel,
  ISupplierListResponseModel,
  ISupplierDashboardResponseModel,
  ISupplierOrderSettingsResponseModel
} from './models/response';
import { ILoginResponseModel } from '../auth/models/response';
import { IUserAddressRequestModel } from '../user/models/request';

const controller = 'supplier';

class SupplierController {

  public static List = (body: ISupplierListRequestModel): Promise<IResponse<IPagingResponse<ISupplierListResponseModel>>> => {
    const result = Connection.POST({
      body,
      action: 'list',
      controller,
    });

    return result;
  };

  public static Details = (id: number): Promise<IResponse<ISupplierDetailsResponseModel>> => {
    const result = Connection.GET({
      action: `${id}`,
      controller,
    });

    return result;
  };

  public static Dashboard = (periodType: SupplierDashboardPeriodType): Promise<IResponse<ISupplierDashboardResponseModel>> => {
    const result = Connection.GET({
      action: 'dashboard',
      controller,
      query: { periodType }
    });

    return result;
  };

  public static ToggleFavorite = (id: number): Promise<IResponse<boolean>> => {
    const result = Connection.PUT({
      body: null,
      action: `favorite/${id}`,
      controller,
    });

    return result;
  };

  public static FavoriteList = (): Promise<IResponse<ISupplierListResponseModel[]>> => {
    const result = Connection.GET({
      action: `favoriteList`,
      controller,
    });

    return result;
  };

  public static CompleteAccount = (body: ISupplierCompleteAccountRequestModel): Promise<IResponse<ILoginResponseModel>> => {
    const result = Connection.PUT({
      body,
      action: 'completeAccount',
      controller,
    });

    return result;
  };

  public static CompleteAccountFile = (body: FormData): Promise<IResponse<ILoginResponseModel>> => {
    const result = Connection.PUT({
      body,
      action: 'completeAccountFile',
      controller,
      noneJSONBody: true,
    });

    return result;
  };

  public static UpdateDelivery = (body: ISupplierUpdateDeliveryRequestModel): Promise<IResponse<boolean>> => {
    const result = Connection.PUT({
      action: 'delivery',
      controller,
      body,
    });

    return result;
  };

  public static UpdatePrimaryAddress = (body: IUserAddressRequestModel): Promise<IResponse<boolean>> => {
    const result = Connection.PUT({
      action: 'primaryAddress',
      controller,
      body,
    });

    return result;
  };

  public static GetDelivery = (): Promise<IResponse<ISupplierDeliveryResponseModel>> => {
    const result = Connection.GET({
      action: 'delivery',
      controller,
    });

    return result;
  };

  public static UpdateOrderSettings = (body: ISupplierUpdateOrderSettingsRequestModel): Promise<IResponse<boolean>> => {
    const result = Connection.PUT({
      action: 'orderSettings',
      controller,
      body,
    });

    return result;
  };

  public static GetOrderSettings = (): Promise<IResponse<ISupplierOrderSettingsResponseModel>> => {
    const result = Connection.GET({
      action: 'orderSettings',
      controller,
    });

    return result;
  };

  public static GetDeliveryById = (supplierId: number): Promise<IResponse<ISupplierDeliveryResponseModel>> => {
    const result = Connection.GET({
      action: `delivery/${supplierId}`,
      controller,
    });

    return result;
  };
};

export default SupplierController;