import { useMemo, useState } from 'react';

export const usePersistState = <Value>(
    storeKey: string,
    defaultValue: Value,
    ignorePersistence = false,
    // ^ Use it when you have some logic which tries to switch the hook usePersistState/useState
    // ^ so it can be done without conditions
): [Value, (value: Value) => void] => {
    const initialValue = useMemo(() => {
        try {
            const valueFromCookie = window.sessionStorage.getItem(storeKey);
            return valueFromCookie && !ignorePersistence ? JSON.parse(valueFromCookie) : defaultValue;
        } catch {
            window.sessionStorage.removeItem(storeKey);
            return defaultValue;
        }
    }, []);
    
    const [state, setState] = useState(initialValue);

    const changeState = (value: Value) => {
        !ignorePersistence && window.sessionStorage.setItem(storeKey, JSON.stringify(value));
        setState(value);
    }

    return [state, changeState];
};