import React from 'react';
import { Switch, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Chart from 'chart.js/auto';

import 'moment/locale/hy-am';
import 'moment/locale/ru';
import 'moment/locale/en-gb';

import './assets/styles/index.scss';

import ROUTES from './platform/constants/routes';
import { AuthContextProvider } from 'contexts/auth';
import Auth from 'modules/auth';
import CompleteAccount from 'modules/complete-account';
import { UserContextProvider } from 'contexts/user';
import SafeRoute from 'components/safeRoute';
import { accountNotCompleteGuard, canAuthorizeGuard } from 'platform/services/routeGuards';
import Layout from 'components/layout';

import AlertModalsWrapper from 'components/alert-modals-wrapper';

Chart.register();

export const browserHistory = createBrowserHistory();

browserHistory.listen(() => {
  window.scrollTo(0, 0);
});

const App = () => (
  <main className="P-main">
    <AuthContextProvider>
      <UserContextProvider>
        <Router history={browserHistory}>
          <AlertModalsWrapper>
            <Switch>
              <SafeRoute
                guard={canAuthorizeGuard}
                path={ROUTES.AUTH.MAIN}
                component={Auth}
              />

              <SafeRoute
                guard={accountNotCompleteGuard}
                path={ROUTES.COMPLETE_ACCOUNT.MAIN}
                component={CompleteAccount}
              />

              <Layout />
            </Switch>
          </AlertModalsWrapper>
        </Router>
      </UserContextProvider>
    </AuthContextProvider>
  </main>
);

export default App;