import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { browserHistory } from 'app';
import _ from 'lodash';
import { usePersistState } from './use-persist-state';
import usePrevious from './use-previous';

const getQueryParamsFromUrl = <QueryParams extends object>() => {
    const params: Partial<QueryParams> = {};
    const query = new URLSearchParams(window.location.search);

    query.forEach((value, key) => {
        const isNumeric = key.includes('$num$');
        const isArray = key.endsWith('[]');
        const normalizedKey = key.replace(/(\$num\$|\[\])/g, ''); // removing aliases ($num$, [])

        if (isArray) {
            const arrayValue = query.getAll(key);
            if (arrayValue) params[normalizedKey] = isNumeric ? arrayValue.map(Number) : arrayValue;
        } else params[normalizedKey] = isNumeric ? +value : value;
    });

    return params as QueryParams;
};

const replaceQueryParams = <QueryParams extends object>(params: QueryParams) => {
    const query = new URLSearchParams();

    Object.keys(params).map(key => {
        const value = params[key];
        if (Array.isArray(value)) {
            const isNumeric = value.some(item => typeof item === 'number');
            const paramKey = `${key}${isNumeric ? '$num$' : ''}[]`;
            value.forEach(each => query.append(paramKey, each));
        } else if (value) {
            const isNumeric = typeof value === 'number';
            const paramKey = `${key}${isNumeric ? '$num$' : ''}`;
            query.append(paramKey, value);
        }
    });

    browserHistory.replace({
        pathname: window.location.pathname,
        search: `?${query.toString()}`,
    });
};

type QueryParamsStateType<QueryParams extends object> = [QueryParams, (value: QueryParams) => void];

const useQueryParams = <QueryParams extends object>(initialParams: QueryParams, persist?: boolean): QueryParamsStateType<QueryParams> => {
    const [queryParams, setQueryParams] = usePersistState(window.location.pathname, window.location.search
        ? { ...initialParams, ...getQueryParamsFromUrl<QueryParams>() } as QueryParams
        : initialParams as QueryParams
    , !persist);

    const prevQueryParams = usePrevious(queryParams);

    useEffect(() => {
        replaceQueryParams(queryParams);

        if (prevQueryParams && prevQueryParams !== queryParams) {
            setQueryParams(queryParams);
        }
    }, [prevQueryParams, queryParams]);

    return [queryParams, setQueryParams];
};

export default useQueryParams;