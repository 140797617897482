
/* injects from baggage-loader */
require('./style.scss');
import React, { useState, useEffect, useContext, SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';
import readExcel from 'read-excel-file';

import FormField from 'components/form-field';
import Steps from 'modules/complete-account/components/steps';
import { CompleteAccountStepsEnum } from 'modules/complete-account/constants/enums';
import { CompleteAccountContext } from 'modules/complete-account/contexts/complete-account';
import { ISupplierCompleteAccountFavoriteCustomerRequestModel } from 'platform/api/supplier/models/request';
import useForm from 'platform/hooks/use-form';
import useTranslations from 'platform/hooks/use-translations';
import { favoriteCustomersFormValidations, initialFavoriteCustomersForm } from '../../form';

const SupplierStep = () => {
    const [excel, setExcel] = useState<File | undefined>();
    const completeAccountTexts = useTranslations('complete_account');
    const [, changeStepData] = useContext(CompleteAccountContext);
    const { form, setForm, onSubmit, buildField } = useForm<ISupplierCompleteAccountFavoriteCustomerRequestModel[]>(
        [initialFavoriteCustomersForm],
        favoriteCustomersFormValidations,
    );

    const toggleForm = (formIndex: number) => {
        if (formIndex === form.length - 1) setForm([...form, initialFavoriteCustomersForm]);
        else setForm(form.filter((item, index) => index !== formIndex));
    }

    useEffect(() => {
        changeStepData(CompleteAccountStepsEnum.Supplier, {
            data: {
                excel,
                favoriteCustomers: form,
            }
        })
    }, [form, excel]);

    const uploadExcel = async (e: SyntheticEvent<HTMLInputElement>) => {
        const file = e.currentTarget.files?.[0];
        if (!file) return;

        try {
            const result = await readExcel(file);

            if (result?.length) {
                const first5Rows = result.slice(1, 6);
                setForm(first5Rows.map(([name, email]) => ({
                    name: name as string,
                    email: email as string,
                })));

                setExcel(file);
            }
        } catch { /* */ }
    }

    const removeExcel = (e: SyntheticEvent) => {
        e.preventDefault();
        setExcel(undefined);
        setForm([initialFavoriteCustomersForm]);
    }

    const fieldProps = { readOnly: !!excel };

    return <>
        <Steps currentStepType={CompleteAccountStepsEnum.Supplier} onSubmit={onSubmit} />

        <div className="P-complete-account-supplier">
            <div className="P-texts-container">
                <h2 className="P-title">{completeAccountTexts.supplier_title}</h2>
                <p className="P-description">{completeAccountTexts.supplier_description}</p>
            </div>
            <form className="G-form P-form" autoComplete="off">
                <div className="P-form-container">
                    {form.map((_, index) => <div key={index} className="P-form-group">
                        <FormField title={completeAccountTexts.supplier_field_name} half {...buildField('name', index, fieldProps)} />
                        <FormField title={completeAccountTexts.supplier_field_email} half {...buildField('email', index, fieldProps)} />

                        {!excel && <>
                            {index === form.length - 1
                                ? <div className="P-form-plus" onClick={() => toggleForm(index)}>+</div>
                                : <div className="P-form-minus" onClick={() => toggleForm(index)}>-</div>}
                        </>}
                    </div>)}
                </div>

                <label className="P-form-upload">
                    <p>{excel ? <>{excel.name} <i className="icon-close" onClick={removeExcel} /></> : <><i className="icon-upload" />{completeAccountTexts.upload_excel}</>}</p>
                    <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={uploadExcel} />
                </label>
            </form>
        </div>
    </>;
};

export default SupplierStep;