import { emailValidator, emptyValidator, onlyDigits, passwordValidator, UKPhoneValidator } from "platform/services/validator";

export const initialForm = {
  firstName: '',
  lastName: '',
  businessName: '',
  companyRegistrationNumber: '',
  phoneNumber: '',
  email: '',
  postalCode: '',
  city: '',
  country: '',
  password: '',
  confirmPassword: '',
  addressText: '',
  addressLat: 1,
  addressLng: 1,

  isByAdmin: true,
};

export const formValidations = {
  email: emailValidator,
  phoneNumber: UKPhoneValidator,
  password: passwordValidator,
  confirmPassword: !initialForm.isByAdmin ? passwordValidator : emptyValidator,
}