import { useEffect, useRef } from "react";

const usePrevious = <Data>(value: Data) => {
    const ref = useRef<Data>();

    useEffect(() => {
        ref.current = value;
    }, [value]);
    
    return ref.current;
};

export default usePrevious;